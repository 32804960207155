(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/analytics/assets/javascripts/bet-slip-events.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/analytics/assets/javascripts/bet-slip-events.js');
"use strict";


const {
  oddsetTrackingLabel
} = svs.oddset_kambi.components.analytics.constants;
const {
  convertObjectToTrackingString
} = svs.oddset_kambi.components.analytics.utils;
const trackAddOutcomeToBetslip = eventData => {
  var _eventData$ecommerce$;
  if (!(eventData !== null && eventData !== void 0 && (_eventData$ecommerce$ = eventData.ecommerce.items) !== null && _eventData$ecommerce$ !== void 0 && _eventData$ecommerce$.length)) {
    return;
  }
  const {
    event,
    categories
  } = eventData.kambi.hit;
  const outcome = eventData.ecommerce.items[0];
  eventData.ecommerce.items.forEach(() => {
    const trackingData = {
      eventId: event.id,
      eventName: event.name,
      league: categories.event_group_four,
      live: categories.is_live,
      market: categories.criterion,
      region: categories.event_group_three,
      sport: categories.event_group_two,
      source: outcome.betslipLocationSource 
    };
    svs.components.analytics.trackEvent({
      category: 'add to betslip',
      action: convertObjectToTrackingString(trackingData),
      opt_label: oddsetTrackingLabel,
      opt_value: outcome.odds_bucket
    });
  });
};
const trackClearBetslip = () => {
  svs.components.analytics.trackEvent({
    category: 'clear betslip',
    opt_label: oddsetTrackingLabel
  });
};
const trackBetReceiptKeepCouponSelection = eventData => {
  let analyticsAction;
  switch (eventData.kambi.interaction.label) {
    case 'Checked':
      analyticsAction = 'Save';
      break;
    case 'Unchecked':
      analyticsAction = 'Continue';
      break;
    default:
      analyticsAction = 'Unknown';
      break;
  }
  svs.components.analytics.trackEvent({
    category: 'onBetReceiptAction',
    opt_label: oddsetTrackingLabel,
    action: analyticsAction
  });
};
setGlobal('svs.oddset_kambi.components.analytics.betSlipEvents', {
  trackAddOutcomeToBetslip,
  trackClearBetslip,
  trackBetReceiptKeepCouponSelection
});

 })(window);
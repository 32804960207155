(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/analytics/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/analytics/assets/javascripts/utils.js');
"use strict";


const convertObjectToTrackingString = obj => {
  const keys = Object.keys(obj);
  const trackingActionString = keys.reduce((acc, key) => "".concat(acc).concat(key, "=").concat(obj[key], ","), '');
  return trackingActionString.slice(0, -1);
};
setGlobal('svs.oddset_kambi.components.analytics.utils', {
  convertObjectToTrackingString
});

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/analytics/assets/javascripts/misc-events.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/analytics/assets/javascripts/misc-events.js');
"use strict";


const trackSandwichFilterClicked = eventData => {
  const {
    interaction
  } = eventData.kambi;
  const isLevelOneClick = interaction.label.includes('L1');
  if (!isLevelOneClick) {
    return;
  }
  svs.components.analytics.trackEvent({
    category: 'navigation oddset',
    action: 'sandwich filter',
    opt_label: interaction.filterLevel1
  });
};
setGlobal('svs.oddset_kambi.components.analytics.miscEvents', {
  trackSandwichFilterClicked
});

 })(window);
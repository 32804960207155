(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/analytics/assets/javascripts/transaction-events.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/analytics/assets/javascripts/transaction-events.js');
"use strict";


const {
  oddsetTrackingLabel
} = svs.oddset_kambi.components.analytics.constants;
const {
  transaction: Transaction,
  transactionItem: TransactionItem,
  trackTransaction
} = svs.components.analytics;
const {
  convertObjectToTrackingString
} = svs.oddset_kambi.components.analytics.utils;
const {
  productIds,
  getProdName
} = svs.components.lbUtils.sportProducts;
const PRODUCT_ID = productIds.ODDSET;
const PRODUCT_NAME = getProdName(PRODUCT_ID);
const SKU = 'EXTOX';
const QUANTITY = 1;
const TAX_AMOUNT = 0;
const SHIPPING_AMOUNT = 0;
const AFFILIATION = undefined;
const trackPayment = transactionData => {
  var _transactionData$ecom, _transactionData$ecom2;
  const outcomes = (_transactionData$ecom = (_transactionData$ecom2 = transactionData.ecommerce) === null || _transactionData$ecom2 === void 0 ? void 0 : _transactionData$ecom2.items) !== null && _transactionData$ecom !== void 0 ? _transactionData$ecom : [];
  const {
    bet: kambiBet
  } = transactionData.kambi.hit;
  const totalCost = transactionData.ecommerce.value;
  const betEvents = outcomes.map(outcome => new TransactionItem(SKU, PRODUCT_NAME, outcome.price, QUANTITY, {
    betType: kambiBet.type,
    betSubType: kambiBet.subtype,
    numberOfEventsInBet: outcomes.length,
    eventId: outcome.item_id,
    eventStartTime: "".concat(outcome.event_date_CET, "T").concat(outcome.event_time_CET),
    eventName: outcome.item_name,
    isLive: outcome.item_brand === 'live',
    sport: outcome.item_category,
    region: outcome.item_category2,
    tournamentName: outcome.item_category3,
    market: outcome.criterion
  }));
  const transaction = new Transaction(transactionData.ecommerce.transaction_id, totalCost, betEvents, TAX_AMOUNT, SHIPPING_AMOUNT, AFFILIATION, PRODUCT_NAME);
  trackTransaction(transaction);
};
const trackCashout = eventData => {
  const trackingData = {
    productName: PRODUCT_NAME,
    productId: PRODUCT_ID,
    transactionId: eventData.ecommerce.transaction_id,
    stake: eventData.ecommerce.value,
    numberOfEventsInBet: eventData.ecommerce.items.length,
    betType: eventData.ecommerce.items[0].combination_id
  };
  svs.components.analytics.trackEvent({
    category: 'Cashout',
    action: convertObjectToTrackingString(trackingData),
    opt_label: oddsetTrackingLabel
  });
};
setGlobal('svs.oddset_kambi.components.analytics.transactionEvents', {
  trackPayment,
  trackCashout
});

 })(window);